/* eslint-disable */
import { postCCOPModel } from './api'
import { create, all } from 'mathjs'

export function deletema(history, mas) {
  var ma = Math.max(mas)
  for (var i = 0; i < ma; i++) {
    history.shift()
  }
  // let first = Object.assign({}, history[0])
  // let last = Object.assign({}, history[history.length - 1])
  // first.name = 'trendline'
  // last.name = 'trendline'
  // history.push(first, last)

  return history
}

export function deviation(history, ma, devnums) {
  var newarray = []
  for (var i = ma; i < history.length; i++) {
    var meandev = deviationcal(history, ma, i)
    // console.log(meandev)
    devnums.forEach(devnum => {
      var upper = meandev[0] + devnum * meandev[1]
      var lower = meandev[0] - devnum * meandev[1]
      var upobj = {
        date: history[i].date,
        maname: devnum + 'S-U',
        marisk: upper,
      }
      newarray.push(upobj)
      var lowobj = {
        date: history[i].date,
        maname: devnum + 'S-L',
        marisk: lower,
      }
      newarray.push(lowobj)
    })

    var obj = {
      date: history[i].date,
      maname: 'ma' + ma,
      marisk: average(history, ma, i),
    }
    newarray.push(obj)
    // var obj = {
    //   date: history[i].date,
    //   maname: "risk",
    //   risk: history[i].risk,
    // }
    // newarray.push(obj)
  }

  return newarray
}

export function movingaverage(history, mas) {
  var newarray = []
  mas.forEach(ma => {
    for (var i = ma; i < history.length; i++) {
      var obj = {
        date: history[i].date,
        maname: 'ma' + ma,
        marisk: average(history, ma, i),
      }
      newarray.push(obj)
    }
  })

  return newarray
}

function deviationcal(history, ma, i) {
  var nums = []
  for (var j = 0; j < ma; j++) {
    nums.push(history[i - j].risk)
  }
  const math = create(all)
  var mean = math.mean(nums)
  var dev = math.std(nums)
  // console.log(mean)
  return [mean, dev]
  // return nums.reduce((a, b) => a + b) / nums.length
}

function average(history, ma, i) {
  var nums = []
  for (var j = 0; j < ma; j++) {
    nums.push(history[i - j].risk)
  }
  const math = create(all)
  var mean = math.mean(nums)
  var dev = math.std(nums)
  // console.log(mean)
  return mean
  // return nums.reduce((a, b) => a + b) / nums.length
}

export async function updateModelName(metricID, metricName) {
  var currentModel = JSON.parse(localStorage.getItem('CCOPModel'))
  console.log(currentModel)
  var metricindex = currentModel.ccop.findIndex(obj => obj.id == metricID)
  console.log(metricindex)
  currentModel.ccop[metricindex].name = metricName
  console.log(currentModel)
  var json = JSON.stringify(currentModel.ccop)
  var name = localStorage.getItem('currentModel')
  if (name == null || name == 'null') {
    name = 'agl_ccop'
  }
  var file = new File([json], name + '.json', {
    type: 'text/plain;charset=utf-8',
  })
  console.log(file)
  // console.log(localStorage.getItem('currentModel'))

  var res = await postCCOPModel(name, file)
  console.log(res)
  if (res == 'Model successfully saved and fusion function successfully generated ') {
    console.log('successfully')
    localStorage.setItem('CCOPModel', JSON.stringify(currentModel))
    window.location.reload()
  }
}
export function BIGenerater() {
  var history = JSON.parse(localStorage.getItem('history_data'))
  // console.log(history)
  var business = []

  if (history.length > 0) {
    for (var i = 0; i < history.length; i++) {
      // var date = getAPIDay(i)
      var date = history[i].time_stamp
      // console.log(date)
      var data = JSON.parse(localStorage.getItem('API' + date.split('T')[0]))
      // console.log(data)
      var cc = {
        time: date.split('T')[0],
        value: parseFloat(data.ccop.business),
        date: date.split('T')[0],
        action: data.ccop.action,
      }
      // console.log(cc)
      business.push(cc)
    }
    // console.log(business)
  }
  return business
}

export function FIGenerater() {
  var history = JSON.parse(localStorage.getItem('history_data'))
  // console.log(history)
  var business = []
  if (history.length > 0) {
    for (var i = 0; i < history.length; i++) {
      // var date = getAPIDay(i)
      var date = history[i].time_stamp
      // console.log(date)
      var data = JSON.parse(localStorage.getItem('API' + date.split('T')[0]))
      // console.log(data)
      var cc = {
        time: date.split('T')[0],
        value: parseFloat(data.ccop.financial),
        date: date.split('T')[0],
        action: data.ccop.action,
      }
      // console.log(cc)
      business.push(cc)
    }
    // console.log(business)
  }
  return business
}
function getMedian(numbers) {
  var median = 0,
    count = numbers.length
  numbers.sort()

  if (count % 2 === 0) {
    // is even
    median = (numbers[count / 2 - 1] + numbers[count / 2]) / 2
  } else {
    // is odd
    median = numbers[(count - 1) / 2]
  }

  return median
}

export async function randomCCOPInstanceGenerator(date) {
  // Generate the CCOP Instance, should take model.
  var s_num = 3
  var t_num = 11
  var o_num = 45
  var S = []
  var T = []
  var O = []
  for (var i = 1; i <= s_num; i++) {
    var data = {
      name: 's' + i,
      value: randomDoubleGenerator(0.5, 1), // Range of S
    }
    S.push(data)
  }
  for (var i = 1; i <= t_num; i++) {
    var data = {
      name: 't' + i,
      value: randomDoubleGenerator(0.4, 1), // Range of T
    }
    T.push(data)
  }
  for (var i = 1; i <= o_num; i++) {
    var data = {
      name: 'o' + i,
      value: randomDoubleGenerator(0.1, 0.8), // Range of O
    }
    O.push(data)
  }
  var res = {
    date: date,
    S: S,
    T: T,
    O: O,
  }
  localStorage.setItem(date, JSON.stringify(res))
  // console.log(res)
  return res
}

export function recent7Days() {
  for (var i = 0; i < 31; i++) {
    var date = getDay(0 - i)
    randomCCOPInstanceGenerator(date)
  }
}

export function getDay(day) {
  //Date Generator with Year
  var today = new Date()
  var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day
  today.setTime(targetday_milliseconds)
  var tYear = today.getFullYear()
  var tMonth = today.getMonth()
  var tDate = today.getDate()
  tMonth = doHandleMonth(tMonth + 1)
  tDate = doHandleMonth(tDate)
  // return tYear + '-' + tMonth + '-' + tDate
  return tDate + '/' + tMonth + '/' + tYear
}

export function getAPIDay(day) {
  //Date Generator with Year
  var today = new Date()
  var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day
  today.setTime(targetday_milliseconds)
  var tYear = today.getFullYear()
  var tMonth = today.getMonth()
  var tDate = today.getDate()
  tMonth = doHandleMonth(tMonth + 1)
  tDate = doHandleMonth(tDate)
  // return tYear + '-' + tMonth + '-' + tDate
  return tYear + '-' + tMonth + '-' + tDate
}

export function getDayAPI(day) {
  //Date Generator with Year
  var today = new Date()
  var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day
  today.setTime(targetday_milliseconds)
  var tYear = today.getFullYear()
  var tMonth = today.getMonth()
  var tDate = today.getDate()
  tMonth = doHandleMonth(tMonth + 1)
  tDate = doHandleMonth(tDate)
  // return tYear + '-' + tMonth + '-' + tDate
  return tYear + '-' + tMonth + '-' + tDate
}

export function getDayWithoutYear(day) {
  //Date Generator without Year
  var today = new Date()
  var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day
  today.setTime(targetday_milliseconds)
  var tMonth = today.getMonth()
  var tDate = today.getDate()
  tMonth = doHandleMonth(tMonth + 1)
  tDate = doHandleMonth(tDate)
  return tDate + '/' + tMonth
}

export function getDayWithoutMonth(day) {
  //Date Generator without Year
  var today = new Date()
  var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day
  today.setTime(targetday_milliseconds)
  var tDate = today.getDate()
  tDate = doHandleMonth(tDate)
  return tDate
}

function doHandleMonth(month) {
  var m = month
  if (month.toString().length == 1) {
    m = '0' + month
  }
  return m
}

export function getHistorical2(level, num, withYear = false, withMonth = true) {
  //Construct element in a level historical data
  if (withMonth == false) {
    // Override
    withYear = false
  }
  var hisData = []
  for (var i = -29; i < 1; i++) {
    var date = getDay(i)
    var dateWithoutYear = getDayWithoutYear(i)
    var dateWithoutMonth = getDayWithoutMonth(i)
    var currentData = JSON.parse(localStorage.getItem(date))
    var levelData = currentData[level]
    var value = levelData[num]
    if (withMonth == false) {
      var currentDay = {
        time: '' + dateWithoutMonth,
        score: Math.round(parseFloat(value.value) * 10 * 100 + Number.EPSILON) / 100,
        date: date,
        level: level,
        num: num,
      }
    } else if (withYear == false) {
      var currentDay = {
        time: dateWithoutYear,
        score: Math.round(parseFloat(value.value) * 10 * 100 + Number.EPSILON) / 100,
        date: date,
        level: level,
        num: num,
      }
    } else {
      var currentDay = {
        time: date,
        score: Math.round(parseFloat(value.value) * 10 * 100 + Number.EPSILON) / 100,
        date: date,
        level: level,
        num: num,
      }
    }

    hisData.push(currentDay)
  }
  // console.log(hisData);
  return hisData
}

export function getHistoricalFromAPI(metricname, withYear = false, withMonth = true, isInt = 0) {
  //Construct element in a level historical data
  if (withMonth == false) {
    // Override
    withYear = false
  }
  var history = JSON.parse(localStorage.getItem('history_data'))
  // console.log(history)
  var hisData = []
  // if (history.length > 7) {
  //   for (var i = -30; i <= 0; i++) {
  //     var date = getAPIDay(i)
  //     var dateWithoutYear = getDayWithoutYear(i)
  //     var dateWithoutMonth = getDayWithoutMonth(i)
  //     var currentData = JSON.parse(localStorage.getItem('API' + date)) //API + date
  //     // console.log(currentData)
  //     if (currentData) {
  //       var ccop = currentData.ccop
  //       console.log(ccop)
  //       var value = ccop[metricname]
  //       if (withMonth == false) {
  //         var currentDay = {
  //           time: '' + dateWithoutMonth,
  //           score: Math.round(parseFloat(value) * 100 + Number.EPSILON) / 100,
  //           date: date,
  //           name: metricname,
  //         }
  //       } else if (withYear == false) {
  //         var currentDay = {
  //           time: dateWithoutYear,
  //           score: Math.round(parseFloat(value) * 100 + Number.EPSILON) / 100,
  //           date: date,
  //           name: metricname,
  //         }
  //       } else {
  //         var currentDay = {
  //           time: date,
  //           score: Math.round(parseFloat(value) * 100 + Number.EPSILON) / 100,
  //           date: date,
  //           name: metricname,
  //         }
  //       }
  //       hisData.push(currentDay)
  //     }
  //   }
  // } else
  if (history.length > 0) {
    for (var i = 0; i < history.length; i++) {
      var date = history[i].time_stamp
      // console.log(date)
      var currentData = JSON.parse(localStorage.getItem('API' + date.split('T')[0]))
      // console.log(currentData)
      var wholedate = date.split('T')[0]
      if (currentData) {
        var ccop = currentData.ccop
        // console.log(ccop)
        var value = ccop[metricname]
        if (withMonth == false) {
          var currentDay = {
            time: wholedate,
            score: isInt
              ? parseInt(value)
              : Math.round(parseFloat(value) * 100 + Number.EPSILON) / 100,
            risk:
              10 - isInt
                ? parseInt(value)
                : Math.round(parseFloat(value) * 100 + Number.EPSILON) / 100,
            date: wholedate,
            name: metricname,
            day: wholedate.split('-')[wholedate.split('-').length - 1],
          }
        } else if (withYear == false) {
          var currentDay = {
            time: wholedate,
            score: isInt
              ? parseInt(value)
              : Math.round(parseFloat(value) * 100 + Number.EPSILON) / 100,
            risk:
              10 - isInt
                ? parseInt(value)
                : Math.round(parseFloat(value) * 100 + Number.EPSILON) / 100,
            date: wholedate,
            name: metricname,
            day: wholedate.split('-')[wholedate.split('-').length - 1],
          }
        } else {
          var currentDay = {
            time: wholedate,
            score: isInt
              ? parseInt(value)
              : Math.round(parseFloat(value) * 100 + Number.EPSILON) / 100,
            risk:
              10 - isInt
                ? parseInt(value)
                : Math.round(parseFloat(value) * 100 + Number.EPSILON) / 100,
            date: wholedate,
            name: metricname,
            day: wholedate.split('-')[wholedate.split('-').length - 1],
          }
        }
        hisData.push(currentDay)
      }
      var currentDate = localStorage.getItem('currentDate') // Get current Date
      // console.log(currentDate)
      if (date.split('T')[0] == currentDate) {
        break
      }
    }
  }

  // console.log(hisData)
  return hisData
}

export function getHistorical(level, num, withYear = false, withMonth = true) {
  //Construct element in a level historical data
  if (withMonth == false) {
    // Override
    withYear = false
  }
  var hisData = []
  for (var i = -29; i < 1; i++) {
    var date = getDay(i)
    var dateWithoutYear = getDayWithoutYear(i)
    var dateWithoutMonth = getDayWithoutMonth(i)
    var currentData = JSON.parse(localStorage.getItem(date))
    var levelData = currentData[level]
    var value = levelData[num]
    if (withMonth == false) {
      var currentDay = {
        x: dateWithoutMonth,
        y: value.value * 10,
        date: date,
        risk: 10 - value.value * 10,
        level: level,
        num: num,
      }
    } else if (withYear == false) {
      var currentDay = {
        x: dateWithoutYear,
        y: value.value * 10,
        date: date,
        risk: 10 - value.value * 10,
        level: level,
        num: num,
      }
    } else {
      var currentDay = {
        x: date,
        y: value.value * 10,
        date: date,
        risk: 10 - value.value * 10,
        level: level,
        num: num,
      }
    }

    hisData.push(currentDay)
  }
  // console.log(hisData);
  return hisData
}

export function midGetName(metricid) {
  //Construct Sankey Data

  var model = JSON.parse(localStorage.getItem('CCOPModel'))
  // console.log(model)
  // console.log(metricid)
  var metric = model.ccop.find(metric => metric.id == metricid)
  // console.log(metric)
  if (metric != undefined) {
    return metric.name
  } else {
    return 'null'
  }
}
export function midGetSubheading(metricid) {
  if (metricid == 's1') {
    return 'Risks associated with overall protective controls of the system'
  } else if (metricid == 's2') {
    return 'Risks associated with overall detective controls of the system'
  } else if (metricid == 's3') {
    return 'Risks associated with overall responsive controls of the system'
  }
}

export function getTopDataStructure(date) {
  //Construct Sankey Data

  var model = JSON.parse(localStorage.getItem('CCOPModelHigh'))
  // console.log(model)
  var currentData = JSON.parse(localStorage.getItem('API' + date))
  // console.log(children)
  // console.log(currentData)
  // var levelData = currentData['T']
  // console.log(levelData);
  var level2 = []
  var between = parseFloat((12 / model.length).toFixed(1)) // Width for each element
  // console.log(between)
  if (between > 6) {
    between = 6
  }
  var spare = (12 - between * model.length) / 2 //Spare width at the beginning

  for (var x = 0; x < model.length; x++) {
    var metricid = model[x]
    // console.log(metricid)
    var cc = {
      // i: nums[x] + '',
      i: model[x],
      x: spare + x * between,
      y: 0,
      w: between,
      h: 1,
      static: true,
      name: midGetName(model[x]),
      percent:
        currentData != null
          ? Math.round(parseFloat(currentData.ccop[metricid] / 10) * 100 + Number.EPSILON) / 100
          : 0,
      // color: 'bg-light',
      monthconfig: {
        data: getHistoricalFromAPI(model[x]), //fetch history data of this metric
        autoFit: true,
        xField: 'time',
        yField: 'score',
        point: {
          size: 5,
          shape: 'diamond',
        },
        padding: 'auto',
        slider: {
          start: 0.6,
          end: 1,
        },
      },
    }
    level2.push(cc)
  }
  console.log(level2)
  return level2
}

export function getSData() {
  //Construct Sankey Data
  var date = localStorage.getItem('currentDate')
  var model = JSON.parse(localStorage.getItem('CCOPModel'))
  var children = model.ccop.filter(metric => metric.id.includes('s'))
  var length = children.length
  var level2 = []
  if (length >= 4) {
    //Max length in a raw
    length = 4
  }
  var between = parseFloat((12 / length).toFixed(1)) // Width for each graph

  for (var x = 0; x < children.length; x++) {
    var metricid = children[x]
    console.log(metricid)
    var yaxis = Math.floor(x / length)
    var cc = {
      // i: nums[x] + '',
      i: children[x].id,
      x: (x % length) * between,
      y: yaxis,
      w: between,
      h: 1,
      static: true,
      name: children[x].id,
      color: 'bg-light',
    }
    level2.push(cc)
  }
  console.log(level2)
  return level2
}

export function getOData() {
  //Construct Sankey Data
  var date = localStorage.getItem('currentDate')

  var model = JSON.parse(localStorage.getItem('CCOPModel'))
  var children = model.ccop.filter(metric => metric.id.includes('o'))
  var length = children.length
  var level2 = []
  if (length >= 4) {
    //Max length in a raw
    length = 4
  }
  var between = parseFloat((12 / length).toFixed(1)) // Width for each graph

  for (var x = 0; x < children.length; x++) {
    var metricid = children[x]
    // console.log(metricid)
    var yaxis = Math.floor(x / length)
    var cc = {
      // i: nums[x] + '',
      i: children[x].id,
      x: (x % length) * between,
      y: yaxis,
      w: between,
      h: 1,
      static: true,
      name: children[x].id,
      color: 'bg-light',
    }
    level2.push(cc)
  }
  console.log(level2)
  return level2
}

export function metricgetScore(metricid) {
  var date = localStorage.getItem('currentDate')
  var currentData = JSON.parse(localStorage.getItem('API' + date))
  if (currentData == null) {
    return 0
  } else {
    return parseFloat(currentData.ccop[metricid]) / 10
  }
}

export function getSankeyData(metricid, date) {
  //Construct Sankey Data

  var model = JSON.parse(localStorage.getItem('CCOPModel'))
  // console.log(model)
  var children = model.ccop.find(metric => metric.id == metricid)
  var currentData = JSON.parse(localStorage.getItem('API' + date))
  // console.log(children)
  // console.log(currentData)
  // var levelData = currentData['T']
  // console.log(levelData);
  var length = children.source.length
  var level2 = []
  if (length >= 4) {
    //Max length in a raw
    length = 4
  }
  var between = parseFloat((12 / length).toFixed(1)) // Width for each graph

  for (var x = 0; x < children.source.length; x++) {
    var metricid = children.source[x]
    // console.log(metricid)
    var yaxis = Math.floor(x / length)
    var cc = {
      // i: nums[x] + '',
      i: children.source[x],
      x: (x % length) * between,
      y: yaxis,
      w: between,
      h: 1,
      static: true,
      name: children.source[x],
      percent: currentData != null ? parseFloat(currentData.ccop[metricid]) / 10 : 0,
      color: 'bg-light',
    }
    level2.push(cc)
  }
  // console.log(level2)
  return level2
}

export function getSankeyStructure(level, id) {
  //Construct Sankey Data
  var model = JSON.parse(localStorage.getItem('CCOPModel'))
  // console.log(model)

  var child = model.ccop.find(metric => metric.id == id)
  // console.log(child)
  var node = [
    {
      id: child.name,
      color: 'hsl(98, 70%, 50%)',
    },
  ]
  var links = []
  for (var x = 0; x < child.source.length; x++) {
    var childname = midGetName(child.source[x])
    // console.log(child.source[x]);
    // console.log(child)
    var cc = {
      id: childname,
      color: 'hsl(98, 70%, 50%)',
    }
    var ll = {
      source: child.name,
      target: childname,
      value: child.weight[x], // Change to divide into the equal weight
      // value: 1 / child.source.length, // Change to divide into the equal weight
    }
    node.push(cc)
    links.push(ll)
  }
  console.log({ nodes: node, links: links })
  return { nodes: node, links: links }
}

export function randomIntGenerator(max, min) {
  return min + Math.floor(Math.random() * Math.floor(max - min))
}

export function randomDoubleGenerator(max, min) {
  var numS = (Math.random() * (max - min) + min).toFixed(2)
  var numF = parseFloat(numS)
  return numF
}

export function level2getLevel3(number) {
  //Not using this function
  var result = []
  console.log(number)
  switch (number) {
    case '1':
      return [
        {
          name: 'Mean Access Point Protection Score',
          score: 0.55,
          index: 0,
          currentindex: 0,
          id: 'o1',
          des:
            'It is the average Access Point Protection Score of all access points in a target network. The Access Protection Score must be calculated first for each access point from the numerical value representing security controls and risk associated with the access point.',
        },
        {
          name: 'Mean Wireless Access Point Protection Score',
          score: 0.55,
          index: 1,
          currentindex: 1,
          id: 'o2',
          weight: 0.5,
          des:
            'It is the average Wireless Access Point Protection Score of all wireless access points in a target network. The Wireless Access Protection Score must be calculated first for each wireless access point from the numerical value representing security controls and risk associated with the wireless access point.',
        },
        {
          name: 'Mean Internet Traffic Protection Score',
          score: 0.55,
          index: 2,
          currentindex: 2,
          id: 'o3',
          weight: 0.5,
          des:
            'It is the average Internet Traffic Protection Score of all internet traffic filtering devices in a target network. The score represents the extent of internet traffic controls in place such as DNS filtering, email filtering and web proxies.',
        },
        {
          name: 'Monthly Incident Count  - Malicious Email',
          score: 0.55,
          index: 3,
          currentindex: 3,
          id: 'o4',
          weight: 0.5,
          des:
            'Score calculeted based on  the average monthly count of security incidents involving malicious email for a calendar year.',
        },
        {
          name: 'Monthly Incident Count - Malicious URL',
          score: 0.55,
          index: 4,
          currentindex: 4,
          id: 'o5',
          weight: 0.5,
          des:
            'Score calculeted based on  the average monthly count of security incidents involving malicious URL for a calendar year.',
        },
        {
          name: 'Monthly Incident Count - Network Penetration',
          score: 0.55,
          index: 5,
          currentindex: 5,
          id: 'o6',
          weight: 0.5,
          des:
            'Score calculeted based on the  average monthly count of security incidents involving network penetration for a calendar year.',
        },
      ]
    case '2':
      return [
        {
          name: 'Mean Stationary End-Point Protection Score',
          score: 0.55,
          index: 6,
          currentindex: 0,
          id: 'o7',
          des:
            'Score calculeted based on  the numerical value representing the effectiveness of security controls on the stationary end-point.',
        },
        {
          name: 'Mean Mobile End-Point Protection Score',
          score: 0.55,
          index: 7,
          currentindex: 1,
          id: 'o8',
          weight: 0.5,
          des:
            'Score calculeted based on  the numerical value representing the effectiveness of security controls on the mobile end-point.',
        },
        {
          name: 'Monthly Incident Count - Malware',
          score: 0.55,
          index: 8,
          currentindex: 2,
          id: 'o9',
          weight: 0.5,
          des:
            'Score calculeted based on  the average monthly count of security incidents involving malware infection for a calendar year.',
        },
        {
          name: 'Monthly Incident Count - Mobile End-Point',
          score: 0.55,
          index: 9,
          currentindex: 3,
          id: 'o10',
          weight: 0.5,
          des:
            'Score calculeted based on the average monthly count of security incidents involving mobile end-point for a calendar year.',
        },
        {
          name: 'Monthly Incident Count - Stationary End-Point',
          score: 0.55,
          index: 10,
          currentindex: 4,
          id: 'o11',
          weight: 0.5,
          des:
            'Score calculeted based on the the average monthly count of security incidents involving stationary end-point for a calendar year.',
        },
      ]
    case '3':
      return [
        {
          name: 'Mean Physical Access Control Score',
          score: 0.55,
          index: 11,
          currentindex: 0,
          id: 'o12',
          des:
            'Score calculeted based on the average Physical Access Control Score of all assets in a network. The Physical Access Control Score represents the effectiveness of physical access controls for the asset.',
        },
        {
          name: 'Monthly Incident Count - Physical Access Violation',
          score: 0.55,
          index: 12,
          currentindex: 1,
          id: 'o13',
          weight: 0.5,
          des:
            'Score calculeted based on the average monthly count of security incidents involving physical access violation for a calendar year.',
        },
      ]
    case '4':
      return [
        {
          name: 'Mean Human Security Score',
          score: 0.55,
          index: 13,
          currentindex: 0,
          id: 'o14',
          des:
            'Score calculeted based on the numeric value indicating the effectiveness of security control involving human agents. Security awareness of individuals handling cyber assets and the security test results are considered in calculating this score.',
        },
        {
          name: 'Monthly Incident Count - Social Engineering',
          score: 0.55,
          index: 14,
          currentindex: 1,
          id: 'o15',
          weight: 0.5,
          des:
            'Score calculeted based on the average monthly count of security incidents involving social engineering for a calendar year.',
        },
        {
          name: 'Monthly Incident Count - Non-Security Staff Reporting',
          score: 0.55,
          index: 15,
          currentindex: 2,
          id: 'o16',
          weight: 0.5,
          des:
            'Score calculeted based on the average monthly count of incidents, first detected by non-security staff reporting.',
        },
        {
          name: 'Monthly Incident Count - Total',
          score: 0.55,
          index: 16,
          currentindex: 3,
          id: 'o17',
          weight: 0.5,
          des:
            'Score calculated based on the  average time in days from the discovery of an incident to the complete recovery from the incident.',
        },
      ]
    case '5':
      return [
        {
          name: 'Mean Asset Connectivity',
          score: 0.55,
          index: 17,
          currentindex: 0,
          id: 'o18',
          des:
            'Score calculated based on the   the average Asset Connectivity of all assets in a target network. Asset Connectivity represents the degree of connectivity within a network. Generally higher asset connectivity is associated with higher risk.',
        },
        {
          name: 'Mean Asset Proximity to Hostile Network',
          score: 0.55,
          index: 18,
          currentindex: 1,
          id: 'o19',
          weight: 0.5,
          des:
            'Score calculated based on the the average Asset Proximity of all assets in a target network. Asset Proximity represents how close an asset is logically located to a hostile network. A lower proximity indicates higher risk',
        },
        {
          name: 'Mean Asset Vulnerability Risk Score',
          score: 0.55,
          index: 19,
          currentindex: 2,
          id: 'o20',
          weight: 0.5,
          des:
            'Score calculated based on the  the average Asset Vulnerability Risk Score of all assets in a target network. The Asset Vulnerability Risk Score is the sum of the Common Vulnerability Scoring System (CVSS) of all vulnerabilities discovered in the asset. A high Asset Vulnerability Risk Score indicates high risk to the asset.',
        },
        {
          name: 'Mean Network Vulnerability Risk Score',
          score: 0.55,
          index: 20,
          currentindex: 3,
          id: 'o21',
          weight: 0.5,
          des:
            'Score calculated based on the  average Asset Vulnerability Risk Score of all assets in a target network. The Asset Vulnerability Risk Score is the sum of the Common Vulnerability Scoring System (CVSS) of all vulnerabilities discovered in the asset. A high Asset Vulnerability Risk Score indicates high risk to the asset.',
        },
        {
          name: 'Monthly Incident Count - Network Penetration',
          score: 0.55,
          index: 21,
          currentindex: 4,
          id: 'o22',
          weight: 0.5,
          des:
            'Score calculated based on the the average monthly count of security incidents involving network penetration for a calendar year.',
        },
      ]
    case '6':
      return [
        {
          name: 'Mean Asset Connectivity',
          score: 0.55,
          index: 22,
          currentindex: 0,
          id: 'o23',
          des:
            'Score calculated based on the   the average Asset Connectivity of all assets in a target network. Asset Connectivity represents the degree of connectivity within a network. Generally higher asset connectivity is associated with higher risk.',
        },
        {
          name: 'Mean Asset Proximity to Hostile Network',
          score: 0.55,
          index: 23,
          currentindex: 1,
          id: 'o24',
          weight: 0.5,
          des:
            'Score calculated based on the the average Asset Proximity of all assets in a target network. Asset Proximity represents how close an asset is logically located to a hostile network. A lower proximity indicates higher risk',
        },
        {
          name: 'Mean Asset Access Control Score',
          score: 0.55,
          index: 24,
          currentindex: 2,
          id: 'o25',
          weight: 0.5,
          des:
            'An average Access Control Score of all assets in a target network. The Access Control Score is higher where there is a higher degree of controls preventing possible unauthorized access',
        },
        {
          name: 'Mean Network Access Control Score',
          score: 0.55,
          index: 25,
          currentindex: 3,
          id: 'o26',
          weight: 0.5,
          des:
            'An average Network Access Control Score of all assets in a target network. This value represents the Asset Access Control Score augmented by the Asset Connectivity and Asset Proximity to Hostile Network. A Higher Network Access Control Score indicates a higher degree of control and lower risk.',
        },
        {
          name: 'Monthly Incident Count - Network Penetration',
          score: 0.55,
          index: 26,
          currentindex: 4,
          id: 'o27',
          weight: 0.5,
          des:
            'Score calculated based on the the average monthly count of security incidents involving network penetration for a calendar year.',
        },
      ]
    default:
      return [
        {
          name: 'Mean Asset Connectivity',
          score: 0.55,
          index: 22,
          currentindex: 0,
          id: 'o23',
          des:
            'Score calculated based on the   the average Asset Connectivity of all assets in a target network. Asset Connectivity represents the degree of connectivity within a network. Generally higher asset connectivity is associated with higher risk.',
        },
        {
          name: 'Mean Asset Proximity to Hostile Network',
          score: 0.55,
          index: 23,
          currentindex: 1,
          id: 'o24',
          weight: 0.5,
          des:
            'Score calculated based on the the average Asset Proximity of all assets in a target network. Asset Proximity represents how close an asset is logically located to a hostile network. A lower proximity indicates higher risk',
        },
        {
          name: 'Mean Asset Access Control Score',
          score: 0.55,
          index: 24,
          currentindex: 2,
          id: 'o25',
          weight: 0.5,
          des:
            'An average Access Control Score of all assets in a target network. The Access Control Score is higher where there is a higher degree of controls preventing possible unauthorized access',
        },
        {
          name: 'Mean Network Access Control Score',
          score: 0.55,
          index: 25,
          currentindex: 3,
          id: 'o26',
          weight: 0.5,
          des:
            'An average Network Access Control Score of all assets in a target network. This value represents the Asset Access Control Score augmented by the Asset Connectivity and Asset Proximity to Hostile Network. A Higher Network Access Control Score indicates a higher degree of control and lower risk.',
        },
        {
          name: 'Monthly Incident Count - Network Penetration',
          score: 0.55,
          index: 26,
          currentindex: 4,
          id: 'o27',
          weight: 0.5,
          des:
            'Score calculated based on the the average monthly count of security incidents involving network penetration for a calendar year.',
        },
      ]
  }
}

export function level2name(number) {
  // Not in use
  console.log(number)
  switch (number) {
    case '1':
      return {
        name: 'Network Perimeter Protection Score',
        id: 't1',
        index: 0,
      }
    case '2':
      return {
        name: 'End-point Protection Score',
        id: 't2',
        index: 1,
      }
    case '3':
      return {
        name: 'Physical Access Control Score',
        id: 't3',
        index: 2,
      }
    case '4':
      return {
        name: 'Human Security Score',
        id: 't4',
        index: 3,
      }
    case '5':
      return {
        name: 'Core Network Vulnerability Control Score',
        id: 't5',
        index: 4,
      }
    case '6':
      return {
        name: 'Core Network Access Control Score',
        id: 't6',
        index: 5,
      }
    case '7':
      return {
        name: 'Data Protection Score',
        id: 't7',
        index: 6,
      }
    case '8':
      return {
        name: 'Mean Time between Incidents',
        id: 't8',
        index: 7,
      }
    case '9':
      return {
        name: 'Threat Awareness Score',
        id: 't9',
        index: 8,
      }
    case '10':
      return {
        name: 'Threat Detection Score',
        id: 't10',
        index: 9,
      }
    case '11':
      return {
        name: 'Incident Response Score',
        id: 't11',
        index: 10,
      }
  }
}
