/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
// import { SearchOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import style from './style.module.scss'
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap'

let searchInput = null

const Search = ({ intl: { formatMessage } }) => {
  const [showSearch, setShowSearch] = useState(false)
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  })

  const showLiveSearch = () => {
    setShowSearch(true)
  }

  const changeSearchText = e => {
    setSearchText(e.target.value)
  }

  const hideLiveSearch = () => {
    // searchInput.blur()
    setShowSearch('')
    setSearchText('')
  }

  const handleKeyDown = event => {
    if (showSearch) {
      const key = event.keyCode.toString()
      if (key === '27') {
        hideLiveSearch()
      }
    }
  }

  return (
    <div className="d-inline-block mr-4">
      <Tooltip placement="bottom" title="Share Current Page" onClick={showLiveSearch}>
        <span className={style.item}>
          <i className={`${style.icon} fe fe-share`} />
        </span>
      </Tooltip>
      {/* <Input
        className={style.extInput}
        placeholder={formatMessage({ id: 'topBar.typeToSearch' })}
        // prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
        style={{ width: 200 }}
        onFocus={showLiveSearch}
      /> */}
      <div
        className={`${
          showSearch ? `${style.livesearch} ${style.livesearchVisible}` : style.livesearch
        }`}
        id="livesearch"
      >
        <button className={style.close} type="button" onClick={hideLiveSearch}>
          <i className="icmn-cross" />
        </button>
        <br />
        <div className="container-fluid">
          <div className={style.wrapper}>
            <div className="card pt-10">
              <div className="card-body">
                <h5 className="mb-4">
                  <strong>Share with partners</strong>
                </h5>
                <Form>
                  <FormGroup>
                    <Label for="exampleSelect">Partners</Label>
                    <Input type="select" name="select" id="exampleSelect">
                      <option>CSCRC</option>
                      <option>Data61</option>
                      <option>Gov of SA</option>
                    </Input>
                    <FormText color="muted">Choose a partner that you want to share with.</FormText>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleSelect">Encryption</Label>
                    <Input type="select" name="select" id="exampleSelect">
                      <option>AES</option>
                      <option>RSA</option>
                      <option>Blowfish</option>
                    </Input>
                    <FormText color="muted">
                      Choose a encryption method that you want to use.
                    </FormText>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleSelect">Validating Period</Label>
                    <Input type="datetime-local" name="select" id="exampleSelect"></Input>
                    <FormText color="muted">Choose a time to finish the sharing.</FormText>
                  </FormGroup>

                  <FormGroup>
                    <Label for="exampleSelect">Content</Label>
                    <Input type="select" name="select" id="exampleSelect">
                      <option>This page</option>
                      <option>This instance</option>
                      <option>All the instance</option>
                    </Input>
                    <FormText color="muted">Choose the content that you want to share.</FormText>
                  </FormGroup>

                  <Button>Submit</Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(Search)
