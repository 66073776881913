/* eslint-disable */
import axios from 'axios'
import { getDayAPI, getDay } from './function'

const urladd = 'https://ccop-api.crest-centre.net/'

export var fetchWordcloud = async (metric, period) => {
  let result = await axios({
    method: 'get',
    url: 'http://13.239.114.242:8989/' + metric + '/' + period,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  })
    .then(function(response) {
      return response.data
    })
    .catch(function(response) {})
  // console.log(result)
  if (metric.includes('emerging')) {
    return result.emerging_topics
  } else {
    return result.counts
  }
}
export var postCCOPModel = async (model, file) => {
  let formData = new FormData()
  formData.append('CCOP_model_file', file)
  // formData.append('ccop_model_id', model)

  let result = await axios({
    method: 'post',
    url: urladd + 'ccop-models/?ccop_model_id=' + model,
    data: formData,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  })
    .then(function(response) {
      return response.data
    })
    .catch(function(response) {})
  console.log(result)
  // localStorage.setItem('CCOPModel', JSON.stringify(result))
  return result
}
export var fetchCCOPModel = async model => {
  let formData = new FormData()
  // formData.append('mobile', mobile);
  // formData.append('password', password);

  let result = await axios({
    method: 'get',
    url: urladd + 'ccop-models/?ccop_model_id=' + model,
    data: formData,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  })
    .then(function(response) {
      return response.data
    })
    .catch(function(response) {})
  // console.log(result)
  localStorage.setItem('CCOPModel', JSON.stringify(result))
  return result
}
// Added to fetch metrics range
export var fetchCCOPrange = async model => {
  model = 'ccop'
  let formData = new FormData()
  let result = await axios({
    method: 'get',
    url: urladd + model + '/metrics-range',
    data: formData,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  })
    .then(function(response) {
      return response.data
    })
    .catch(function(response) {})
  // console.log(result)
  localStorage.setItem('CCOPModelRange', JSON.stringify(result))
  return result
}

export var fetchVisConfig = async model => {
  // model = 'ccop'
  let formData = new FormData()
  let result = await axios({
    method: 'get',
    url: urladd + model + '/metrics-range',
    data: formData,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  })
    .then(function(response) {
      return response.data
    })
    .catch(function(response) {})
  // console.log(result)
  return JSON.parse(result[0])
}

export var fetchCCOPModelAll = async () => {
  let formData = new FormData()
  // formData.append('mobile', mobile);
  // formData.append('password', password);

  let result = await axios({
    method: 'get',
    url: urladd + 'ccop-models/',
    data: formData,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  })
    .then(function(response) {
      return response.data
    })
    .catch(function(response) {})
  // console.log(result)
  localStorage.setItem('CCOPModelAll', JSON.stringify(result))
  return result
}

export var fetchCCOPModelHigh = async model => {
  let formData = new FormData()
  // formData.append('mobile', mobile);
  // formData.append('password', password);

  let result = await axios({
    method: 'get',
    url: urladd + 'ccop-models/?ccop_model_id=' + model + '&high=high-level',
    data: formData,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  })
    .then(function(response) {
      return response.data
    })
    .catch(function(response) {})
  console.log(result)
  localStorage.setItem('CCOPModelHigh', JSON.stringify(result))
  return result
}

export var fetchFrequencyHistory = async (
  model,
  metric,
  frequency,
  period,
  isInt,
  labelName = '',
) => {
  if (frequency == undefined) {
    frequency = 1
  }
  if (period == undefined) {
    period = 60
  }
  if (period < 2) {
    period = 2
  }
  // console.log(model)
  // console.log(metric)
  // console.log(frequency)
  // console.log(period)
  let today = new Date().toISOString().slice(0, 10)
  var hisData = []
  var local = localStorage.getItem(labelName + model + metric + frequency + period + today)
  // console.log(local)
  // console.log(local.length)
  if (local == undefined || local.length == 2) {
    let result = await axios({
      method: 'get',
      url:
        urladd +
        'frequency_data/' +
        model +
        '?reporting_freq=' +
        frequency +
        '&reporting_period=' +
        period +
        '&met_id=' +
        metric +
        '&ccop_model_id=' +
        model,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    })
      .then(function(response) {
        return response.data
      })
      .catch(function(response) {})
    // console.log(result)

    if (result.length > 0) {
      for (var i = 0; i < result.length; i++) {
        // console.log(result[i]);
        var value = result[i].value
        // console.log(value);
        var date = result[i].time_stamp.split('T')[0]
        var currentDay = {
          time: date,
          score: isInt ? parseInt(value) : parseFloat(value),
          risk: isInt ? parseInt(value) : parseFloat(value),
          date: date,
          name: metric,
          labelName: labelName,
        }
        hisData.push(currentDay)
      }
    }
    localStorage.setItem(
      labelName + model + metric + frequency + period + today,
      JSON.stringify(hisData),
    )
  } else {
    return JSON.parse(local)
  }

  // console.log(hisData);

  return hisData
}

export var fetchHistory = async model => {
  //Fetch 32 days history data
  var date = Math.floor(Date.now() / 1000)
  var date1 = date + 1 * 24 * 60 * 60
  var date2 = date1 - 41 * 24 * 60 * 60
  // console.log(date1)
  // console.log(date2)

  let result = await axios({
    method: 'get',
    url: urladd + model + '?date1=' + date2 + '&date2=' + date1,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  })
    .then(function(response) {
      return response.data
    })
    .catch(function(response) {})
  // console.log(result)
  localStorage.setItem('history_data', JSON.stringify(result))
  if (result.length > 0) {
    var misseddata = haveall(result[0].ccop)

    for (var i = 0; i < result.length; i++) {
      // console.log(result[i].time_stamp)
      var timestamp = result[i].time_stamp
      var date = timestamp.split('T')[0] //Construct the instance model
      var time = result[i].time_stamp
      if (misseddata.length > 0) {
        console.log("don't have all the data")
        var newinstance = constructData(misseddata, result[i].ccop)
        var res = {
          date: date,
          time: time,
          ccop: newinstance,
        }
        // console.log(res)
        localStorage.setItem('API' + date, JSON.stringify(res)) //Store the history data, API + date
      } else {
        var res = {
          date: date,
          time: time,
          ccop: result[i].ccop,
        }
        // console.log(res)
        localStorage.setItem('API' + date, JSON.stringify(res)) //Store the history data, API + date
      }
    }
    return result
  } else {
    localStorage.setItem('history_data', JSON.stringify([]))
    return null
  }
}

function constructData(misseddata, instance) {
  var model = JSON.parse(localStorage.getItem('CCOPModel'))
  // console.log(misseddata)
  // console.log(instance)
  // console.log(model)
  for (var i = 0; i < misseddata.length; i++) {
    var currentmetric = model.ccop.find(obj => {
      return obj.id === misseddata[i]
    })
    // console.log(currentmetric)
    var value = valueFromChildren(currentmetric, instance)
    // for (var j = 0; j < currentmetric.source.length; j++) {
    //   value = value + currentmetric.weight[j] * instance[currentmetric.source[j]];
    // }
    // console.log(value)
    instance[misseddata[i]] = value
  }
  // console.log(instance)
  return instance
}

function valueFromChildren(currentmetric, instance, model) {
  //TODO: If there are no values for the children
  var value = 0
  for (var j = 0; j < currentmetric.source.length; j++) {
    value = value + currentmetric.weight[j] * instance[currentmetric.source[j]]
  }
  // console.log(value);
  return value.toFixed(2)
}

function haveall(instance) {
  var allmetric = []
  var model = JSON.parse(localStorage.getItem('CCOPModel'))
  // console.log(model)
  for (var i = 0; i < model.ccop.length; i++) {
    // for (var i = 0; i < 2; i++) {
    // console.log(instance);
    var metricid = model.ccop[i].id
    // console.log(metricid);
    var value = instance[metricid]
    // console.log(value);
    if (value == undefined) {
      allmetric.push(metricid)
    }
    // console.log(instance);
  }
  return allmetric
}

export var postRange = async (model, range) => {
  let formData = new FormData()
  console.log(model)
  console.log(range)
  formData.append('metrics-data', "'" + range + "'")
  formData.append('ccop_model_id', model)

  let result = await axios({
    method: 'post',
    url: urladd + model + '/metrics-range',
    data: formData,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  })
    .then(function(response) {
      return response.data
    })
    .catch(function(response) {})
  console.log(result)
  // localStorage.setItem('CCOPModel', JSON.stringify(result))
  return result
}
