/* eslint-disable */
export function eligibleRoute(route) {
  var eligibleroute = [
    '/',
    '/setup/generator',
    '/setup/recommendation',
    '/setup/modeleditor',
    '/setup/editor',
    '/setup/visconfig',
    '/setup/switcher',
    '/setup/uploadTemplate',
    '/pageframer',
    '/dashboard',
    '/dashboard/overall',
    '/dashboard/overall',
    '/ccop/allmetrics',
    '/ccop/overall',
    '/dashboard/dynamic',
    '/dashboard/allmetrics',
    '/dashboard/snapshot',
    '/auth/login',
    '/auth/forgot-password',
    '/auth/register',
    '/auth/404',
  ]
  return eligibleroute.findIndex(e => e == route)
}
