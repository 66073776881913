/* eslint-disable */
import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'
import Layout from 'layouts'
import { eligibleRoute } from './functions'
const routes = [
  // Setup
  {
    path: '/setup/generator',
    Component: lazy(() => import('pages/setup/generator')),
    exact: true,
  },
  {
    path: '/setup/recommendation',
    Component: lazy(() => import('pages/setup/recommendation')),
    exact: true,
  },
  {
    path: '/setup/modeleditor',
    Component: lazy(() => import('pages/setup/modeleditor')),
    exact: true,
  },
  {
    path: '/setup/editor',
    Component: lazy(() => import('pages/setup/modeleditor/modeleditor')),
    exact: true,
  },
  {
    path: '/setup/visconfig',
    Component: lazy(() => import('pages/setup/visconfig')),
    exact: true,
  },
  {
    path: '/setup/switcher',
    Component: lazy(() => import('pages/setup/switcher')),
    exact: true,
  },
  {
    path: '/setup/uploadTemplate',
    Component: lazy(() => import('pages/setup/uploadTemplate')),
    exact: true,
  },
  // Dashboards
  {
    path: '/pageframer',
    Component: lazy(() => import('pages/dashboard/ccop/pageframer')),
    exact: true,
  },
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/dashboard/dashboard')),
    exact: true,
  },
  {
    path: '/dashboard/overall',
    Component: lazy(() => import('pages/dashboard/dashboard/overall')),
    exact: true,
  },
  {
    path: '/ccop/overall',
    Component: lazy(() => import('pages/dashboard/ccop/overall')),
    exact: true,
  },
  {
    path: '/ccop/allmetrics',
    Component: lazy(() => import('pages/dashboard/ccop/allmetric')),
    exact: true,
  },
  {
    path: '/ccop/osint',
    Component: lazy(() => import('pages/dashboard/ccop/osint')),
    exact: true,
  },
  {
    path: '/dashboard/dynamic',
    Component: lazy(() => import('pages/dashboard/dashboard/dynamic')),
    exact: true,
  },
  {
    path: '/dashboard/allmetrics',
    Component: lazy(() => import('pages/dashboard/dashboard/allmetrics')),
    exact: true,
  },
  {
    path: '/dashboard/snapshot',
    Component: lazy(() => import('pages/dashboard/dashboard/snapshot')),
    exact: true,
  },
  {
    path: '/dashboard/metrics/:a',
    Component: lazy(() => import('pages/dashboard/dashboard/metrics')),
    exact: true,
  },
  {
    path: '/dashboard/riskview/:a',
    Component: lazy(() => import('pages/dashboard/dashboard/riskview')),
    exact: true,
  },

  // Apps
  {
    path: '/apps/messaging',
    Component: lazy(() => import('pages/apps/messaging')),
    exact: true,
  },
  {
    path: '/apps/calendar',
    Component: lazy(() => import('pages/apps/calendar')),
    exact: true,
  },
  {
    path: '/apps/mail',
    Component: lazy(() => import('pages/apps/mail')),
    exact: true,
  },
  {
    path: '/apps/profile',
    Component: lazy(() => import('pages/apps/profile')),
    exact: true,
  },
  {
    path: '/apps/gallery',
    Component: lazy(() => import('pages/apps/gallery')),
    exact: true,
  },
  // Extra Apps
  {
    path: '/apps/github-explore',
    Component: lazy(() => import('pages/apps/github-explore')),
    exact: true,
  },
  {
    path: '/apps/github-discuss',
    Component: lazy(() => import('pages/apps/github-discuss')),
    exact: true,
  },
  {
    path: '/apps/digitalocean-droplets',
    Component: lazy(() => import('pages/apps/digitalocean-droplets')),
    exact: true,
  },
  {
    path: '/apps/digitalocean-create',
    Component: lazy(() => import('pages/apps/digitalocean-create')),
    exact: true,
  },
  {
    path: '/apps/google-analytics',
    Component: lazy(() => import('pages/apps/google-analytics')),
    exact: true,
  },
  {
    path: '/apps/wordpress-post',
    Component: lazy(() => import('pages/apps/wordpress-post')),
    exact: true,
  },
  {
    path: '/apps/wordpress-posts',
    Component: lazy(() => import('pages/apps/wordpress-posts')),
    exact: true,
  },
  {
    path: '/apps/wordpress-add',
    Component: lazy(() => import('pages/apps/wordpress-add')),
    exact: true,
  },
  {
    path: '/apps/todoist-list',
    Component: lazy(() => import('pages/apps/todoist-list')),
    exact: true,
  },
  {
    path: '/apps/jira-dashboard',
    Component: lazy(() => import('pages/apps/jira-dashboard')),
    exact: true,
  },
  {
    path: '/apps/jira-agile-board',
    Component: lazy(() => import('pages/apps/jira-agile-board')),
    exact: true,
  },
  {
    path: '/apps/helpdesk-dashboard',
    Component: lazy(() => import('pages/apps/helpdesk-dashboard')),
    exact: true,
  },

  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  // Read URL and record the target
  const a = window.location.href
  // console.log(a)
  const u = a.split('#')
  var eligible = eligibleRoute(u[1])
  // console.log(eligible)
  if (eligible < 0) {
    localStorage.setItem('route', u[1])
  }
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/pageframer" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
